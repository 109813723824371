import Vue from 'vue'
import useValidator from '@/mixins/useValidator'

export default Vue.extend({
  mixins: [useValidator],
  props: {
    initialValues: {
      type: Object,
      default () {
        return {}
      }
    },
    showErrors: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    formErrors () {
      // @ts-ignore
      return this.showErrors ? this.errors : {}
    }
  },
  methods: {
    handleChange (values: Record<string, unknown>) {
      let isValidated

      // @ts-ignore
      if (this.validationRules) {
        // @ts-ignore
        isValidated = this.validate(values, this.validationRules)
      }

      this.$emit('change', { ...values }, isValidated)
    }
  }
})
