<template>
  <app-layout>
    <div class="px-4 px-sm-5 container">
      <async-object
        should-fetch
        :fetch-method="() => $store.dispatch('returnRequests/fetchById', {
          id: $route.params.id,
          storeId: currentStoreId
        })"
      >
        <div class="heading d-flex justify-content-between mb-5">
          <h2>Demande de retour {{ returnRequestsReference }}</h2>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="mb-4">
              <router-link class="btn btn-link btn-sm" :to="{ name: 'returns.index' }" active-class="active">
                <fa-icon :icon="['fas', 'chevron-left']" size="sm"></fa-icon>&nbsp;Retour
              </router-link>
            </div>
          </div>
        </div>

        <div class="alert alert-danger" v-if="hasError">
          {{ errorMessage }}
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <div class="card mb-4">
              <div class="card-body">
                <h4 class="header-title mb-4">Sommaire</h4>
                <dl class="row">
                  <dt class="col-4">Commande</dt>
                  <dd class="col-8">{{ orderReference }}</dd>
                </dl>
                <dl class="row">
                  <dt class="col-4">Date de la demande</dt>
                  <dd class="col-8">{{ $shortDateTime(returnRequestsDate) }}</dd>
                </dl>
                <dl class="row">
                  <dt class="col-4">Statut</dt>
                  <dd class="col-8">{{ returnRequestsStatus }}</dd>
                </dl>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="card mb-4">
              <div class="card-body">
                <h4 class="header-title mb-4">Adresse de facturation</h4>
                <p class="address">
                  {{ shippingAddress.name }}
                  <template v-if="shippingAddress.company">
                    <br> {{ shippingAddress.company }}
                  </template>
                  <br>{{ shippingAddress.address }}
                  <template v-if="shippingAddress.address2">
                    <br> {{ shippingAddress.address2 }}
                  </template>
                  <br>{{ shippingAddress.city }}, {{ shippingAddress.province }}, {{ shippingAddress.postalCode }}
                  <br>Canada
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="card mb-4">
              <div class="card-body p-0">
                <h4 class="header-title with-padding">Articles à retourner</h4>

                <div class="table-responsive">
                  <table class="table nowrap-table">
                    <thead>
                    <tr>
                      <th>Article</th>
                      <th class="col-100">Quantité</th>
                      <th>Statut</th>
                      <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="item in returnRequestItems">
                      <return-request-item-row :key="item.id" :item="item" @click="handleItemStatusUpdate" />
                    </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </async-object>
    </div>
  </app-layout>
</template>

<script>
import { get } from 'lodash-es'
import ReturnRequestItemRow from '@/components/returns/ReturnRequestItemRow'
import userData from '@/mixins/user-data'
import formatsCurrency from '@/mixins/formatsCurrency'
import formatsDate from '@/mixins/formatsDate'
import returnStatus from '@/mixins/returnStatus'
import http from '@/utils/http'

export default {
  mixins: [userData, returnStatus, formatsCurrency, formatsDate],
  components: {
    ReturnRequestItemRow
  },
  data () {
    return {
      hasError: false,
      errorMessage: "Une erreur interne s'est produite. Réessayez dans quelques instants."
    }
  },
  computed: {
    returnRequests () {
      return this.$store.getters['returnRequests/getDetailsById'](this.$route.params.id)
    },
    returnRequestItems () {
      return this.returnRequests ? this.returnRequests.returnRequestItems : []
    },
    returnRequestsReference () {
      return get(this.returnRequests, 'reference')
    },
    returnRequestsDate () {
      return get(this.returnRequests, 'createdAt')
    },
    returnRequestsStatus () {
      const status = get(this.returnRequests, 'status')
      return this.getReturnRequestStatusText(status)
    },
    orderReference () {
      return get(this.returnRequests, 'order.reference')
    },
    shippingAddress () {
      return get(this.returnRequests, 'shippingAddress', {})
    }
  },
  methods: {
    async handleItemStatusUpdate (payload) {
      if (payload.status === 'returnConfirmed') {
        try {
          this.hasError = false
          this.errorMessage = "Une erreur interne s'est produite. Réessayez dans quelques instants."

          await http.post(`/v1/stores/${this.currentStoreId}/return-receipts`, payload)

          this.$store.dispatch('returnRequests/fetchById', {
            id: this.$route.params.id,
            storeId: this.currentStoreId
          })
        } catch (error) {
          if (error.response.status === 400) {
            this.errorMessage = error.response.data
          }
          this.hasError = true
        }
      } else {
        this.$store.dispatch('returnRequests/patchItemsStatus', {
          id: this.$route.params.id,
          itemId: payload.id,
          status: payload.status,
          rejectionMessage: payload.rejectionMessage
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.address {
  line-height: 24px;
}
</style>
