import Vue from 'vue'

export default Vue.extend({
  methods: {
    getReturnRequestStatusText (value: string): string {
      switch (value) {
        case 'closed':
          return 'Finalisée'
        case 'accepted':
          return 'Acceptée'
        case 'pending':
        default:
          return 'Effectuée'
      }
    },
    getReturnRequestItemStatusText (value: string): string {
      switch (value) {
        case 'returnDenied':
          return 'Retour refusé'
        case 'pendingReturn':
          return 'Retour accepté'
        case 'returnExpired':
          return 'Retour expiré'
        case 'completed':
          return 'Retour complété'
        case 'returnRequested':
        default:
          return 'Demande de retour'
      }
    }
  }
})
