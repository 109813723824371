<template>
  <v-modal title="Confirmer la réception">
    <div class="modal-body">
      <div class="alert alert-danger" v-if="hasError">
        Une erreur interne s'est produite. Réessayez dans quelques instants.
      </div>

      <p>
        En confirmant la réception de ce produit, une note de crédit sera émise au client et un remboursement du montant correspondant lui sera envoyé. Il n'est pas possible d'annuler cette action.
      </p>

      <v-dynamic-form
        :fields="fields"
        :initial-values="form"
        @change="handleChange"
        :errors="formErrors"
      ></v-dynamic-form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" @click="$emit('cancel')">Annuler</button>
      <button type="button" class="btn btn-primary" @click="submit">Confirmer</button>
    </div>
  </v-modal>
</template>

<script>
import handleForm from '@/mixins/handle-form'
import useValidator from '@/mixins/useValidator'

export default {
  props: ['item'],
  mixins: [handleForm, useValidator],
  data () {
    return {
      hasError: false,
      form: {
        receivedOn: new Date(),
        quantityAccepted: 0,
        quantityRejected: 0,
        rejectionReason: null
      }
    }
  },
  computed: {
    formErrors () {
      return this.errors
    },
    fields () {
      return [
        {
          name: 'receivedOn',
          label: 'Date de réception',
          type: 'date-picker',
          required: true
        },
        {
          name: 'quantityAccepted',
          label: 'Quantité acceptée',
          type: 'number',
          max: this.item.quantity,
          required: true
        },
        {
          name: 'quantityRejected',
          label: 'Quantité refusée',
          type: 'number',
          max: this.item.quantity,
          required: true
        },
        {
          name: 'rejectionReason',
          label: 'Motif du refus',
          required: true,
          isVisible: (values) => {
            return values.quantityRejected > 0
          }
        }
      ]
    },
    validationRules () {
      let validation = {
        receivedOn: 'required',
        quantityAccepted: `required|max:${this.item.quantity}`,
        quantityRejected: `required|max:${this.item.quantity}`
      }

      if (this.form.quantityRejected > 0) {
        validation = { ...validation, rejectionReason: 'required' }
      }

      return validation
    }
  },
  methods: {
    handleChange (values) {
      this.validate(values, this.validationRules)
      this.form = { ...values }
    },
    async submit () {
      try {
        this.hasError = false

        const validationRules = this.validationRules

        const validate = this.validate(this.form, validationRules)

        if (!this.form.quantityAccepted && !this.form.quantityRejected) {
          this.errors = {
            quantityAccepted: ['La somme des quantités acceptées et rejetées doit être supérieure à 0'],
            quantityRejected: ['La somme des quantités rejetées et acceptées doit être supérieure à 0']
          }
          return
        }

        if (validate) {
          this.$emit('confirm', this.form)
        }
      } catch (error) {
        this.hasError = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
